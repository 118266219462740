import { FilterOption } from './types'

export const IBMI_BASE_ENDPOINT = process.env.IBMI_BASE_ENDPOINT || ''
export const IBMI_USERNAME = process.env.IBMI_USERNAME || ''
export const IBMI_PASSWORD = process.env.IBMI_PASSWORD || ''
export const INTERNAL_API_BASE_URL = process.env.INTERNAL_API_BASE_URL || ''
export const TIMEOUT_MS = 25900
export const DATE_RANGE_OPTIONS: FilterOption[] = [
  { label: 'Last 30 Days', value: '30' },
  { label: 'Last 60 Days', value: '60' },
  { label: 'Last 90 Days', value: '90' },
  { label: 'Last 120 Days', value: '120' },
  { label: 'Last 180 Days', value: '180' },
  { label: 'Last Year', value: '365' },
  // { label: 'Last 2 Years', value: '730' },
]

export const DATE_INDEX: indexType = {
  30: 'Last 30 Days',
  60: 'Last 60 Days',
  90: 'Last 90 Days',
  120: 'Last 120 Days',
  180: 'Last 180 Days',
  365: 'Last Year',
  730: 'Last 2 Years',
}

export const ORIGIN_TERMINAL_OPTIONS: FilterOption[] = [
  { label: 'All', value: 'All' },
  { label: 'Afton', value: '3' },
  { label: 'Big Piney', value: '48' },
  { label: 'Billings', value: '4' },
  { label: 'Bozeman', value: '5' },
  { label: 'Butte', value: '6' },
  { label: 'Casper', value: '8' },
  { label: 'Cheyenne', value: '12' },
  { label: 'Denver', value: '16' },
  { label: 'Douglas', value: '20' },
  { label: 'Evanston', value: '24' },
  { label: 'Gillette', value: '28' },
  { label: 'Granby', value: '32' },
  { label: 'Great Falls', value: '34' },
  { label: 'Havre', value: '26' },
  { label: 'Helena', value: '35' },
  { label: 'Idaho Falls', value: '38' },
  { label: 'Jackson', value: '36' },
  { label: 'Kalispell', value: '42' },
  { label: 'Laramie', value: '44' },
  { label: 'Missoula', value: '46' },
  { label: 'Powell', value: '52' },
  { label: 'Rapid City', value: '56' },
  { label: 'Rawlins', value: '60' },
  { label: 'Riverton', value: '64' },
  { label: 'Rock Springs', value: '68' },
  { label: 'Salt Lake City', value: '72' },
  { label: 'Scottsbluff', value: '74' },
  { label: 'Sheridan', value: '76' },
  { label: 'Spokane', value: '78' },
  { label: 'Worland', value: '84' },
]

export interface indexType {
  [index: number]: string
}
export const TERMINAL_OPTION: indexType = {
  3: 'Afton',
  48: 'Big Piney',
  4: 'Billings',
  5: 'Bozeman',
  6: 'Butte',
  8: 'Casper',
  12: 'Cheyenne',
  16: 'Denver',
  20: 'Douglas',
  24: 'Evanston',
  28: 'Gillette',
  32: 'Granby',
  34: 'Great Falls',
  26: 'Havre',
  35: 'Helena',
  38: 'Idaho Falls',
  36: 'Jackson',
  42: 'Kalispell',
  44: 'Laramie',
  46: 'Missoula',
  52: 'Powell',
  56: 'Rapid City',
  60: 'Rawlins',
  64: 'Riverton',
  68: 'Rock Springs',
  72: 'Salt Lake City',
  74: 'Scottsbluff',
  76: 'Sheridan',
  78: 'Spokane',
  84: 'Worland',
}

export const DESTINATION_TERMINAL_OPTIONS: FilterOption[] = [
  { label: 'All', value: 'All' },
  { label: 'Afton', value: '3' },
  { label: 'Big Piney', value: '48' },
  { label: 'Billings', value: '4' },
  { label: 'Bozeman', value: '5' },
  { label: 'Butte', value: '6' },
  { label: 'Casper', value: '8' },
  { label: 'Cheyenne', value: '12' },
  { label: 'Denver', value: '16' },
  { label: 'Douglas', value: '20' },
  { label: 'Evanston', value: '24' },
  { label: 'Gillette', value: '28' },
  { label: 'Granby', value: '32' },
  { label: 'Great Falls', value: '34' },
  { label: 'Havre', value: '26' },
  { label: 'Helena', value: '35' },
  { label: 'Idaho Falls', value: '38' },
  { label: 'Jackson', value: '36' },
  { label: 'Kalispell', value: '42' },
  { label: 'Laramie', value: '44' },
  { label: 'Missoula', value: '46' },
  { label: 'Powell', value: '52' },
  { label: 'Rapid City', value: '56' },
  { label: 'Rawlins', value: '60' },
  { label: 'Riverton', value: '64' },
  { label: 'Rock Springs', value: '68' },
  { label: 'Salt Lake City', value: '72' },
  { label: 'Scottsbluff', value: '74' },
  { label: 'Sheridan', value: '76' },
  { label: 'Spokane', value: '78' },
  { label: 'Worland', value: '84' },
]

export const SCAC_CODES: { [key: string]: string } = {
  ABF: 'ABF FREIGHT SYSTEM',
  ABF1: 'ABF FREIGHT SYSTEM',
  ABF3: 'ABF FREIGHT SYSTEM ',
  ABF4: 'ABF FREIGHT SYSTEM ',
  ABF5: 'ABF FREIGHT SYSTEM ',
  ACTI: 'ACTION CARGO CORPORATION',
  ALLS: 'ALLSTATES TRUCKING',
  ANDY: 'ANDYS',
  BBFG: 'BENJAMIN BEST FREIGHT',
  BOKA: 'BOKA FREIGHT INC',
  BROW: 'BC FREIGHTWAYS',
  CAPA: 'CAP LOGISTICS',
  CCYQ: 'CROSS COUNTRY FREIGHT',
  CETR: 'CENTRAL TRANSPORT',
  CHOI: 'CHOICE TRANSPORTATION LP',
  CLTL: 'BILL CLARK TRUCK LINE',
  CONB: 'CONWAY FREIGHT',
  CONM: 'CON-WAY WESTERN EXP ',
  CON3: 'CON-WAY WESTERN EXP ',
  CON4: 'CON-WAY WESTERN EXP',
  CON5: 'CON-WAY WESTERN EXP ',
  CTMO: 'CAT TRANSPORT',
  CUST: 'CUSTOM COMPANIES',
  CXKI: 'CORLETT EXPRESS TRUCKING',
  DATS: 'CROSS COUNTRY FREIGHT',
  DHRN: 'DOHRN TRANSFER COMPANY',
  DLDS: 'DIAMOND LINE DELIVERY',
  DTSB: 'DIVERSIFIED TRANSFER',
  EXLA: 'ESTES EXPRESS LINES',
  FACK: 'FACKLER TRANSPORTATION',
  FED2: 'FEDERAL EXPRESS - CAS',
  HRCF: 'HERCULES',
  IL: 'BEYOND INTERLINE UNKNOWN',
  INTW: 'INTERWEST TRANSPORTATION',
  ITTS: 'I&T TRANSFER AND STORAGE',
  LAKE: 'LME, INC.',
  MAGN: 'MAGNUM LTL',
  MERG: 'CROSS COUNTRY FREIGHT',
  MIKE: "MIKE'S TRANSFER",
  MMEI: 'MIDWEST MOTOR EXPRESS',
  MTVL: 'MOUNTAIN VALLEY EXPRESS',
  OAKH: 'OAK HARBOR FREIGHT LINES',
  OAKI: 'OAK HARBOR FREIGHT LINES',
  OAKM: 'OAK HARBOR FREIGHT LINES',
  OAKN: 'OAK HARBOR FREIGHT LINES',
  OAKU: 'OAK HARBOR FREIGHT LINES',
  OAKW: 'OAK HARBOR FREIGHT LINES',
  ODFL: 'OLD DOMINION FREIGHT LINE',
  RACP: 'CROSS COUNTRY FREIGHT',
  RDFS: 'ROADRUNNER TRANSPORTATION',
  RDWM: 'YRC WORLDWIDE',
  RDWS: 'YELLOW FREIGHT   (SCT)',
  RDWW: 'YRC WORLDWIDE',
  RDW1: 'YRC WORLDWIDE ',
  RDW3: 'YRC WORLDWIDE ',
  RDW4: 'YRC WORLDWIDE ',
  RDW5: 'YRC WORLDWIDE ',
  RDW6: 'YRC WORLDWIDE ',
  RDW7: 'YRC WORLDWIDE   ',
  REDD: 'USF REDDAWAY-CORP OFFICE',
  RLKS: 'R&L TRUCKLOAD SERVICES',
  RNLO: 'R & L TRANSFER',
  RUDE: 'RUDE TRANSPORTATION',
  SAIA: 'SAIA',
  SLDS: 'S & L DELIVERY',
  SVXL: 'SUPERIOR SERVICE TRANSP',
  UPGF: 'TFORCE FREIGHT',
  USRD: 'U.S. ROAD',
  WRDS: 'WESTERN REGIONAL DELIVERY',
  YRCW: 'YRC FREIGHT',
  ZIPX: 'NEKDOSHA, LLC',
}

export const STATUS_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'All Short', value: 'A' },
  { label: 'Delivered', value: 'C' },
  { label: 'Out for Delivery', value: 'B' },
  { label: 'Damage', value: 'D' },
  { label: 'Non-Standard', value: 'N' },
  { label: 'Not Statused', value: 'X' },
  { label: 'In Transit', value: ' ' },
  { label: 'Overage', value: 'O' },
  { label: 'Pickup Complete', value: 'P' },
  { label: 'Received Short from I/L', value: 'I' },
  { label: 'Refused', value: 'R' },
  { label: 'Return to Destination', value: 'E' },
  { label: 'Shortage', value: 'S' },
  { label: 'Undeliverable', value: 'U' },
  { label: 'Delayed - Weather', value: 'W' },
]

export const TYPE_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'Freight Bill', value: 'freight' },
  { label: 'Cartage', value: 'cartage' },
]

export const INVOICE_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'Paid In Full', value: 'Paid In Full' },
  { label: 'Past Due', value: 'Past Due' },
  { label: 'Outstanding Balance', value: 'Outstanding Balance' },
  { label: 'Revised', value: 'Revised' },
  { label: 'Invoice Ready', value: 'Invoice Ready' },
  { label: 'Invoice Not Required', value: 'Invoice Not Required' },
  { label: 'Invoice Pending', value: 'Invoice Pending' },
]

export const BREAKPOINTS = { S: 375, M: 768, L: 1024, XL: 1280 }
